import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Heading } from "@atoms/typography"
import { Section, Container, Flex, Column, Content } from "@atoms/layout"
import { media } from "@styles/breakpoints"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ContentCarousel = ({ extension }) => {
  const colors = JSON.parse(extension.imageColors)
  const [extensionSlider, setExtensionSlider] = useState()
  const [extensionNav, setExtensionNav] = useState()

  const slider = useRef(null)
  const nav = useRef(null)

  useEffect(() => {
    setExtensionSlider(slider.current)
    setExtensionNav(nav.current)
  }, [])

  const extensionSliderSettings = {
    autoplay: true,
    autoplaySpeed: 7500,
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    lazyLoad: "progressive",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const extensionNavSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7500,
    centerMode: true,
    dots: false,
    focusOnSelect: true,
    slidesToShow: 5,
    variableWidth: true,
  }

  const width = 800
  const quality = 90

  return (
    <Section lg lgTop light={extension.background}>
      <Container>
        <Flex alignCenter rowReverse={extension.layout}>
          <Column half>
            <Wrapper>
              <StaticImage
                className="phone"
                src="../../../../assets/visuals/extensions/phone.png"
                quality={quality}
                width={width}
                alt="phone background for slider"
              />
              <ExtensionSlider
                {...extensionSliderSettings}
                ref={slider}
                asNavFor={extensionNav}
              >
                {extension.images.map((slide, index) => (
                  <GatsbyImage
                    image={slide.gatsbyImageData}
                    key={index}
                    alt=""
                    title={slide.title}
                  />
                ))}
              </ExtensionSlider>
            </Wrapper>
            <ExtensionNav
              {...extensionNavSettings}
              ref={nav}
              asNavFor={extensionSlider}
            >
              {colors.map((color, index) => {
                return (
                  <div key={index}>
                    <Circle color={color.color} />
                  </div>
                )
              })}
            </ExtensionNav>
          </Column>
          <Column half>
            <Content paragraph>
              <Heading html="h2" level="h2">
                {extension.title}
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: extension.content,
                }}
              />
            </Content>
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default ContentCarousel

export const extensionContentCarousel = graphql`
  fragment ExtensionContentCarousel on DatoCmsContentWCarousel {
    id
    title
    layout
    imageColors
    background
    content
    model {
      apiKey
    }
    images {
      alt
      title
      gatsbyImageData(
        width: 800
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  display: block;

  .phone {
    position: absolute !important;
    top: 0;
    height: 100%;
    left: 0;
    transform: scale(0.99) translateY(-0.15rem);
    width: 100%;
    z-index: 2;
  }
`

const ExtensionSlider = styled(Slider)`
  flex: 1 1 100%;
  width: 100%;
`

const Circle = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 10rem;
  height: 2.5rem;
  margin: 0.75rem;
  position: relative;
  width: 2.5rem;

  ${media.sm`
    height: 4rem;
    margin: 1rem;
    width: 4rem;
  `}

  &:after {
    border: 1px solid ${(props) => props.color};
    border-radius: 10rem;
    content: "";
    height: 3.5rem;
    left: -0.5rem;
    position: absolute;
    opacity: 0;
    top: -0.5rem;
    transition: var(--transitionBase);
    width: 3.5rem;

    ${media.sm`
      height: 5rem;
      width: 5rem;
    `}
  }

  &:hover {
    cursor: pointer;

    &:after {
      opacity: 0.25;
    }
  }
`

const ExtensionNav = styled(Slider)`
  flex: 1 1 100%;
  width: 100%;

  .slick-track {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      border: 1px dashed var(--gray--40);
      content: "";
      height: 1px;
      position: absolute;
      max-width: 240px;
      top: 47.5%;
      transform: translateY(-50%);
      width: 100%;
      z-index: -1;

      ${media.xxs`
        width: 280px;
      `}

      ${media.sm`
        top: 50%;
        width: 75%;
      `}
    }

    .slick-slide,
    .slick-slide div,
    .slick-active,
    .slick-current,
    .slick-current div,
    .slick-active div {
      &:focus {
        outline: none;
      }
    }

    .slick-current {
      ${Circle} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`
