import React from "react"
import { graphql } from "gatsby"

import Header from "@organisms/sections/extensions/header"
import ContentImage from "@organisms/sections/extensions/contentImage"
import ContentCarousel from "@organisms/sections/extensions/contentCarousel"
import Help from "@organisms/sections/extensions/help"
import Layout from "@utils/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"

const ExtensionTemplate = ({ data }) => {
  const extension = data.datoCmsExtension

  return (
    <Layout>
      <HelmetDatoCms seo={extension.seoMetaTags}/>
      <div data-datocms-noindex>
        {extension.content.map((block, index) => (
          <React.Fragment key={index}>
            {
              block.model.apiKey === "header" ? <Header extension={block} name={extension.title}/> :
                block.model.apiKey === "content_w_image" ?  <ContentImage extension={block} /> :
                  block.model.apiKey === "content_w_carousel" ? <ContentCarousel extension={block} /> :
                    block.model.apiKey === "help" ? <Help extension={block} /> :
                      <h1>No Corrosponding Block</h1>
            }
          </React.Fragment>
        ))}
      </div>
    </Layout>
  )
}

export default ExtensionTemplate

export const fetchExtensionTemplate = graphql`
  query fetchExtensionTemplate($slug: String!) {
    datoCmsExtension(slug: { eq: $slug }) {
      title
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ...ExtensionHeader
        ...ExtensionContentImage
        ...ExtensionContentCarousel
        ...ExtensionHelp
      }
    }
   
  }
`
