import React, {useRef} from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Heading } from "@atoms/typography"
import { Section, Container, Flex, Column, Content } from "@atoms/layout"
import { media } from "@styles/breakpoints"

const Header = ({ extension, name }) => {
  const loop = extension.videoLoop
  const duration = extension.visual.video.duration
  const video = useRef()
  
  const pauseVideo = () => {
    const currentTime = Math.round(video.current.currentTime)
    loop === false &&
      duration <= currentTime + 1 &&
        video.current.pause()
  }
    
  return (
    <SectionHeader>
      <ContainerHeader>
        <Flex twoCol alignCenter>
          <Column half>
            <Content paragraph>
              <Heading html="h6" level="sh" strong gray>
                {name}
              </Heading>
              <Heading html="h1" level="h1">
                {extension.title}
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: extension.content,
                }}
              />
            </Content>
          </Column>
        </Flex>
      </ContainerHeader>
      {extension.visual && 
       <Player>
          <VideoWrapper>
          <Video id="vid" ref={video} autoPlay loop={extension.videoLoop} muted playsInline poster={extension.videoPlaceholder.url}  onTimeUpdate={pauseVideo}>
              <source src={extension.visual.video.mp4Url} type="video/mp4" />
            </Video>
          </VideoWrapper>
       </Player>
      }
    </SectionHeader>
  )
}

export default Header

export const extensionHeader = graphql`
  fragment ExtensionHeader on DatoCmsHeader {
    id
    title
    content
    visual {
      video {
        mp4Url
        duration
        frameRate
        thumbnailUrl
        muxPlaybackId
      }
    }
    videoPlaceholder {
      url
    }
    videoLoop
    model {
      apiKey
    }
  }
`

const SectionHeader = styled(Section)`
  align-items: center;
  background-color: #F2F9F9;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-direction: column-reverse;
  padding: 0;

  ${media.sm`
    padding: var(--spacingSection);
  `}
`

const ContainerHeader = styled(Container)`
  padding: var(--spacingSection);
  padding-bottom: var(--spacing--md);
  padding-top: 0;

  ${media.sm`
    padding: 0;
    padding-top: var(--spacing--lg);
    padding-bottom: var(--spacing--lg);
  `}
`

const Player = styled.div`
  max-width: 50rem;
  padding-top: var(--spacing--lg);
  width: 100%;

  ${media.sm`
    padding-top: 0;
    position: absolute;
    right: 0;
    width: 50vw; 
  `}
`

const VideoWrapper = styled.div`
  position: relative;
  margin-bottom: var(--spacing--sm);
   
  ${media.sm`
    margin-bottom: 0;
  `}

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((3 / 4) * 100%);
  }
`

const Video = styled.video`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: 0px auto;
  object-fit: cover;
  width: 100%;
  height: 100%; 
`
