import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Bowser from "bowser"

import { Heading } from "@atoms/typography"
import { Section, Container, Flex, Column, Content } from "@atoms/layout"

const ContentImage = ({ extension }) => {
  const [safari, setSafari] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const browser = Bowser.getParser(window.navigator.userAgent)

      const isSafari = browser.satisfies({
        macos: {
          safari: ">1",
        },
      })
      setSafari(isSafari)
    }
  }, [])

  return (
    <Section lg lgTop light={extension.background}>
      <Container>
        <Flex alignCenter rowReverse={extension.layout}>
          <Column half>
            {extension.image.isImage === true ? (
              <GatsbyImage
                image={extension.image.gatsbyImageData}
                alt={extension.image.alt}
                title={extension.image.title}
              />
            ) : (
              <Video
                preload="metadata"
                autoPlay
                loop
                muted
                playsInline
                poster={extension.image.video.thumbnailUrl}
              >
                {safari ? (
                  <source
                    src={extension.image.video.streamingUrl}
                    type="video/mp4"
                  />
                ) : (
                  <source src={extension.image.video.mp4Url} type="video/mp4" />
                )}
              </Video>
            )}
          </Column>
          <Column half>
            <Content paragraph>
              <Heading html="h2" level="h2">
                {extension.title}
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: extension.content,
                }}
              />
            </Content>
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default ContentImage

export const extensionContentImage = graphql`
  fragment ExtensionContentImage on DatoCmsContentWImage {
    id
    title
    layout
    content
    background
    model {
      apiKey
    }
    image {
      alt
      title
      isImage
      gatsbyImageData(
        width: 800
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
      video {
        mp4Url
        streamingUrl
        duration
        thumbnailUrl
        muxPlaybackId
      }
    }
  }
`

const Video = styled.video`
  position: relative;
  width: 100%;
`
