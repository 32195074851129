import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Button from "@atoms/button"
import { Heading } from "@atoms/typography"
import { Section, Container } from "@atoms/layout"

const Header = ({ extension }) => {
  return (
    <Section lg lgTop light={extension.background}>
      <HelpContainer containerMedium>
        <Heading html="h3" level="h2" center>
          {extension.content}
        </Heading>
        <Button href={extension.buttonLink} primary border center>
          {extension.buttonText}
        </Button>
      </HelpContainer>
    </Section>
  )
}

export default Header

export const extensionHelp = graphql`
  fragment ExtensionHelp on DatoCmsHelp {
    id
    buttonText
    buttonLink
    background
    content
    model {
      apiKey
    }
  }
`

const HelpContainer = styled(Container)`
  text-align: center;
`
